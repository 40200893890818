<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DataSelect',
})
</script>
<template>
  <a-select
    :value="state.value"
    :mode="mode"
    label-in-value
    :placeholder="placeholder"
    style="width: 100%"
    :filter-option="false"
    :options="state.options"
    @search="fetchUser"
    :not-found-content="originOptions?.length ? '请搜索' : undefined"
    :maxTagCount="1"
    @change="handleChange"
  >
    <template #maxTagPlaceholder="value">
      <a-tooltip>
        <template #title>{{ value.map((v) => v.label).join(',') }}</template>
        + {{ value.length }} ...
      </a-tooltip>
    </template>
  </a-select>
</template>
<script lang="ts" setup name="DataSelect">
  import { PropType, reactive, watch } from 'vue'
  import { debounce } from 'lodash-es'
  // import { queryEnumUsers } from '@admin/api/system/user'
  import useDataSelect from './useDataSelect'

  const emits = defineEmits(['change', 'update:value'])

  defineOptions({
    inheritAttrs: true,
  })

  const props = defineProps({
    value: {
      type: Array as PropType<string[] | number[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    mode: {
      type: String as PropType<'multiple' | 'tags'>,
      default: 'multiple',
    },
    options: {
      type: Array as PropType<{ value: string | number; label: string }[]>,
      default: () => [],
    },
    defaultValue: {
      type: Array as PropType<(string | number)[]>,
      default: () => [],
    },
  })

  const { originOptions } = useDataSelect()

  const state = reactive<any>({
    options: [],
    value: [],
  })

  const handleChange = (e) => {
    state.value = e
    if (e == undefined) {
      emits('change', e, [])
      return
    }

    if (!e?.length) {
      state.options = []
    }
    if (Array.isArray(e)) {
      emits(
        'change',
        e.map((item) => item.value || item),
        originOptions.value.filter((item) => e.map((v) => v.value).includes(item.value)),
      )
    } else {
      emits(
        'change',
        e.value,
        originOptions.value.filter((item) => [e].map((v) => v.value).includes(item.value)),
      )
    }
  }

  watch(
    () => props.options,
    (v) => {
      if (v?.length) {
        state.options = v
      }
    },
    {
      immediate: true,
    },
  )

  watch(
    () => [props.defaultValue, originOptions.value],
    ([defaultValue, originOptions]) => {
      const options = props.options?.length ? props.options : originOptions
      if (Array.isArray(defaultValue)) {
        state.value = defaultValue.map((v) => options.find((item) => item.value == v))
      } else {
        state.value = options.find((item) => item.value == defaultValue)
      }
    },
    {
      immediate: true,
      deep: true,
    },
  )

  const fetchUser = debounce((value) => {
    if (!value) {
      state.options = []
      return
    }
    state.options = []
    // 大小写不敏感
    const list = originOptions.value.filter((item) =>
      item.label.toLowerCase().includes(value.toLowerCase()),
    )
    state.options = list
  }, 300)
</script>
